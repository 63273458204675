import React from "react";
import {
  capitalize,
  getBgColor,
  getSocialIcon,
  getSocialSmIcon,
} from "../../utils";
import styles from "./link-card.module.css";

const LinkCard = ({ item, handleClick, user }) => {
  const finalTheme =
    user && (user.activeProfile === "business" ? user.biz_theme : user.theme);

  return (
    <div onClick={handleClick} className={styles.container}>
      {(user.activeProfile === "personal" && user.colorLinkIcons) ||
      (user.activeProfile === "business" && user.biz_colorLinkIcons) ? (
        <div
          style={{ backgroundColor: getBgColor(finalTheme) }}
          className={styles.linkCover}
        >
          <img
            src={item.icon ? item.icon : getSocialSmIcon(item.type)}
            alt=""
            className={styles.linkImg}
          />
        </div>
      ) : (
        <div key={item.id} className={styles.linkCover}>
          <img
            src={item.icon ? item.icon : getSocialIcon(item.type)}
            alt=""
            className={styles.linkImg}
          />
        </div>
      )}
      <div>
        <p className={styles.titleText}>
          {item.title || capitalize(item.type)}
        </p>
        <p className={styles.descText}>{item.description}</p>
      </div>
    </div>
  );
};

export default LinkCard;
