import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Modal } from "react-bootstrap";
import Axios from "axios";
import styles from "./interacModal.module.css";
import { validateEmail } from "../../utils";

const InteracModal = ({ showModal, toggleModal, uid, email }) => {
  const [loading, setLoading] = useState(false);
  const onCopy = async () => {
    navigator?.clipboard.writeText(email).then(function() {
      toggleModal();
      alert('Copied');
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });
  };

  return (
    <Modal
      show={showModal}
      ///backdrop="static"
      onHide={toggleModal}
      //containerClassName={styles.containerClassName}
      contentClassName={styles.contentClassName}
      //backdropClassName={styles.backdropClassName}
      //className={styles.modalClassName}
      //dialogClassName={styles.dialogClassName}
    >
      <Container fluid>
        <Row>
          <Col className="p-0">
            <div className="text-center mt-4 mb-3">
            <p className={styles.modalTitle}>Copy Intrac Email</p>
            <div className={styles.emailContainer}>
              <p className={styles.email}>{email}</p>
            </div>
              <button
                className={styles.modalConnectBtn}
                onClick={onCopy}
                disabled={loading}
              >
                Copy
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default InteracModal;
