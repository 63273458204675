import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

import {
  addAnalytics,
  capitalize,
  getSocialIcon,
  getSocialUrl,
  getYoutubeId,
  handleExportVCF,
} from "../../utils";
import styles from "./ThemeOne.module.css";
import ContentModal from "../../components/contentModal";
import Spinning from "../../components/common/Spinning";

import cover from "../../assets/images/cover.png";
import profile from "../../assets/images/avatar.png";
import themeOneBg from "../../assets/images/themeOne.png";
import PublicProducts from "../../components/PublicProducts";
import LinkCard from "../../components/LinkCard";
import CreateProfileButton from "../../components/CreateProfileButton";

const CountDiv = ({ title, count }) => (
  <div style={{ marginTop: 10 }}>
    <p className={styles.smallText}>{title}</p>
    <p className={styles.smallText} style={{ fontSize: 30, color: "#000" }}>
      {count}
    </p>
  </div>
);

const ThemeOne = ({
  user,
  embeddedLinks,
  highlightLinks,
  normalLinks,
  showModal,
  toggleModal,
}) => {
  useEffect(() => {
    const tempData = {
      uid: user.uid,
      viewerUid: null,
    };
    addAnalytics("https://api.netwotap.com/analytics/stats/view", tempData);
  }, []);

  const redirect = (item) => {
    if (user) {
      const tempData = {
        uid: user.uid,
        viewerUid: null,
        type: item.type,
        linkId: item.id,
      };
      addAnalytics("https://api.netwotap.com/analytics/stats/tap", tempData);
    }
    const url = getSocialUrl(item);
    window.open(url, "_blank");
  };

  return (
    <Container fluid className="main-content-container p-0">
      {user ? (
        <>
          <ContentModal
            showModal={showModal}
            toggleModal={toggleModal}
            uid={user.uid}
          />
          <div
            className={styles.page}
            style={{
              backgroundImage:
                user.activeProfile === "business"
                  ? `url('${user.biz_wallpaper}')`
                  : `url(${user.wallpaper})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className={styles.pageContent}>
              <div className={styles.coverContainer}>
                <img className={styles.cover} alt="" src={themeOneBg} />
              </div>
              <div className={styles.dpContainer}>
                <img
                  className={styles.dp}
                  alt=""
                  src={
                    (user.activeProfile === "business"
                      ? user.biz_photo
                      : user.photo) || profile
                  }
                />
              </div>
              <div className="text-center">
                <div className={styles.userInfoCover}>
                  <p className={styles.usernameText}>
                    {" "}
                    {(user.activeProfile === "business"
                      ? user.biz_firstname
                      : user.firstname) +
                      " " +
                      (user.activeProfile === "business"
                        ? user.biz_lastname
                        : user.lastname)}
                  </p>
                  <p className={styles.smallText}>
                    {user.activeProfile === "business"
                      ? user.biz_location
                      : user.location}
                  </p>
                  <p className={styles.description}>
                    {user.activeProfile === "business"
                      ? user.biz_description
                      : user.description}
                  </p>
                </div>
                <button
                  onClick={() => handleExportVCF(user)}
                  className={styles.button}
                  style={{ borderRadius: 30 }}
                >
                  Save Contact
                </button>

                <div className={styles.content}>
                  <CountDiv title="Profile Views" count={user.profileViews} />
                  <CountDiv title="Content Downloads" count={user.downloads} />
                </div>
              </div>
              
              <div className="text-center mt-2">
                <p className={styles.shareText} onClick={toggleModal} style={{ cursor: "pointer" }}>
                  Share your contacts with{" "}
                  {(user.activeProfile === "business"
                    ? user.biz_firstname
                    : user.firstname) +
                    " " +
                    (user.activeProfile === "business"
                      ? user.biz_lastname
                      : user.lastname)}
                </p>
              </div>
              {embeddedLinks.map((item) => (
                <div key={item.id} className="mt-4 mx-3">
                  <LiteYouTubeEmbed
                    id={getYoutubeId(item.value)}
                    title="Embedded Video"
                  />
                </div>
              ))}
              <div className="mt-5 mx-3">
                {highlightLinks.map((item) => (
                  <LinkCard
                    key={item.id}
                    item={item}
                    handleClick={() => redirect(item)}
                    user={user}
                  />
                ))}
              </div>
              {/* LINKS */}
              <Container fluid className="mt-5">
                <Row>
                  {normalLinks.map((item, index) => (
                    <Col key={item.id} md={6}>
                      <div
                        onClick={() => redirect(item)}
                        className={styles.linkList}
                      >
                        <img
                          alt="link"
                          src={item.icon ? item.icon : getSocialIcon(item.type)}
                          className={styles.linkIcon}
                        />
                        <p className={styles.linkText}>
                          {item.title || capitalize(item.type)}
                        </p>
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
              {user.categories.length > 0 && (
                <PublicProducts
                  publicCategories={user.categories}
                  sellerUid={user.uid}
                  isTable={Boolean(user.isTable)}
                  isDelivery={Boolean(user.isDelivery)}
                />
              )}
            </div>
            <CreateProfileButton />
          </div>
        </>
      ) : (
        <Spinning></Spinning>
      )}
    </Container>
  );
};

export default ThemeOne;
