import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

import {
  addAnalytics,
  capitalize,
  getSocialIcon,
  getSocialUrl,
  getYoutubeId,
  handleExportVCF,
} from "../../utils";
import styles from "./ThemeTwo.module.css";
import ContentModal from "../../components/contentModal";
import Spinning from "../../components/common/Spinning";

import profile from "../../assets/images/avatar.png";
import downloadIcon from "../assets/icons/download.png";
import PublicProducts from "../../components/PublicProducts";
import LinkCard from "../../components/LinkCard";
import CreateProfileButton from "../../components/CreateProfileButton";

const ThemeTwo = ({
  user,
  embeddedLinks,
  highlightLinks,
  normalLinks,
  showModal,
  toggleModal,
}) => {
  useEffect(() => {
    const tempData = {
      uid: user.uid,
      viewerUid: null,
    };
    addAnalytics("https://api.netwotap.com/analytics/stats/view", tempData);
  }, []);

  const redirect = (item) => {
    if (user) {
      const tempData = {
        uid: user.uid,
        viewerUid: null,
        type: item.type,
        linkId: item.id,
      };
      addAnalytics("https://api.netwotap.com/analytics/stats/tap", tempData);
    }
    const url = getSocialUrl(item);
    window.open(url, "_blank");
  };

  return (
    <Container fluid className="main-content-container p-0">
      {user ? (
        <>
          <ContentModal
            showModal={showModal}
            toggleModal={toggleModal}
            uid={user.uid}
          />
          <div
            className={styles.page}
            // style={{
            //   backgroundImage:
            //     user.activeProfile === "business"
            //       ? `url('${user.biz_wallpaper}')`
            //       : `url(${user.wallpaper})`,
            //   backgroundRepeat: "no-repeat",
            //   backgroundSize: "cover",
            // }}
          >
           <div className={styles.bgTheme} style={{
              backgroundImage:
                user.activeProfile === "business"
                  ? `url('${user.biz_wallpaper}')`
                  : `url(${user.wallpaper})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }} ></div>
            <div className={styles.pageContent}>
              <Container fluid>
                <Row className="align-items-center justify-content-center position-relative">
                  <Col md={5}>
                    <div className={styles.profileImgCover}>
                      <img
                        className={styles.fullImg}
                        alt="profile"
                        src={
                          (user.activeProfile === "business"
                            ? user.biz_photo
                            : user.photo) || profile
                        }
                      />
                    </div>
                  </Col>
                  <Col md={5}>
                    <p className={styles.usernameText}>
                      {(user.activeProfile === "business"
                        ? user.biz_firstname
                        : user.firstname) +
                        " " +
                        (user.activeProfile === "business"
                          ? user.biz_lastname
                          : user.lastname)}
                    </p>
                    <p className={styles.description}>
                      {user.activeProfile === "business"
                        ? user.biz_description
                        : user.description}
                    </p>
                  </Col>
                  <button
                    onClick={() => handleExportVCF(user)}
                    className={styles.downloadCover}
                  >
                    <img
                      alt="download"
                      src={downloadIcon}
                      className={styles.downloadIcon}
                    />
                  </button>
                </Row>
                <Row>
                  <Col>
                    {embeddedLinks.map((item) => (
                      <div key={item.id} className="mt-4 mx-2">
                        <LiteYouTubeEmbed
                          id={getYoutubeId(item.value)}
                          title="Embedded Video"
                        />
                      </div>
                    ))}
                    <div className="mt-5 mx-3">
                      {highlightLinks.map((item) => (
                        <LinkCard
                          key={item.id}
                          item={item}
                          handleClick={() => redirect(item)}
                          user={user}
                        />
                      ))}
                    </div>
                    {normalLinks.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => redirect(item)}
                        className={styles.linkList}
                      >
                        <img
                          alt="link"
                          src={item.icon ? item.icon : getSocialIcon(item.type)}
                          className={styles.linkIcon}
                        />
                        <p className={styles.linkText}>
                          {item.title || capitalize(item.type)}
                        </p>
                      </div>
                    ))}
                    {user.categories.length > 0 && (
                      <div className="mt-5 mb-4">
                        <PublicProducts
                          publicCategories={user.categories}
                          sellerUid={user.uid}
                          isTable={Boolean(user.isTable)}
                          isDelivery={Boolean(user.isDelivery)}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
              <CreateProfileButton />
            </div>
          </div>
        </>
      ) : (
        <Spinning></Spinning>
      )}
    </Container>
  );
};

export default ThemeTwo;
