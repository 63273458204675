/* eslint-disable import/no-anonymous-default-export */
import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";
import AuthLayout from "./layouts/Auth";

import Profile from './views/profile';

const PrivateRoute = ({ component: Component, ...rest }) => {

  return (

    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route {...rest} render={props => (
      //  !!currentUser ?
        <Component {...props} />
        // : <Redirect to="/login" />
    )} />
  );
};

const PublicRoute = ({ component: Component, restricted, ...rest }) => {


  return (
    <Route {...rest} render={props => (
      // !!currentUser && restricted ?
        // <Redirect to="/dashboard" />
         <Component {...props} />
    )} />
  );
};



export default [
  {
    path: "/:linkId",
    component: () => <PublicRoute to="/:linkId" component={Profile} />, 
    exact: true, 
    layout: AuthLayout
  },
  {
    path: "/",
    component: () => <PublicRoute to="/:linkId" component={Profile} />, 
    exact: true, 
    layout: AuthLayout
  }
];
