import Axios from "axios";
import { store } from "react-notifications-component";
import { urls } from "../data/linksData";
const TOKEN_KEY = "jwt";

export const login = () => {
  localStorage.setItem(TOKEN_KEY, "TestLogin");
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {
  // if (localStorage.getItem(TOKEN_KEY)) {
  //     return true;
  // }
  return false;
};

export const Constants = {
  MaterialColors: [
    "#f44336",
    "#e91e63",
    "#9c27b0",
    "#673ab7",
    "#3f51b5",
    "#2196f3",
    "#03a9f4",
    "#00bcd4",
    "#3267ef",
    "#4caf50",
    "#ff5722",
  ],
};

export const newGuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16).toUpperCase();
  });
};
export const newGuid16 = () => {
  return "xxxxxxxxxxxxxxxx".replace(/[xy]/g, function(c) {
    var r = (Math.random() * 16) | 0,
      v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16).toUpperCase();
  });
};

export const shorten = (str, maxLen, separator = " ", trail = false) => {
  if (str.length <= maxLen) return str;
  return (
    str.substr(0, str.lastIndexOf(separator, maxLen)) +
    (trail ? (str.length > maxLen ? "..." : "") : "")
  );
};

export const capitalize = (str = "") => {
  const lowerStr = str.toLowerCase();
  return lowerStr.charAt(0).toUpperCase() + lowerStr.slice(1);
};

export const trimString = (s) => {
  var l = 0,
    r = s.length - 1;
  while (l < s.length && s[l] == " ") l++;
  while (r > l && s[r] == " ") r -= 1;
  return s.substring(l, r + 1);
};

export const notify = (msg, success) => {
  store.addNotification({
    title: success ? "Great!" : "Oops!",
    message: msg,
    type: success ? "success" : "danger",
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
    },
  });
};

export const isSet = (item) => {
  if (!item || item == null || item == undefined || item == "") {
    return false;
  }

  return true;
};

export const secondsToDhms = (seconds) => {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
  var hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : "";
  return dDisplay + hDisplay + mDisplay + sDisplay;
};

export const validateEmail = (email) => {
  var regexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(String(email).toLowerCase());
};

// Flumble App

export const getSocialIcon = (name) => {
  const urlItem = urls.filter(
    (x) => x.name.toLowerCase() === name.toLowerCase()
  )[0];
  return urlItem?.icon || null;
};

export const getSocialSmIcon = (name) => {
  const urlItem = urls.filter(
    (x) => x.name.toLowerCase() === name.toLowerCase()
  )[0];
  return urlItem ? urlItem.icon_w : null;
};

export const getSocialLink = (name) => {
  console.log(name);
  const urlItem = urls.find((x) => x.name.toLowerCase() === name.toLowerCase());
  console.log(urlItem ? urlItem.url : '');
  return (urlItem ? urlItem.url : '') || '';
};

export const getSocialUrl = (item) => {
  console.log(item);
  if (!item.type) {
    return item.value;
  }

  switch (item.type.toLowerCase()) {
    case "call":
      return `tel:${item.value}`;
    case "text":
    case "message":
      return `sms:${item.value}`;
    case "mail":
      return `mailto:${item.value}`;
    // case "facebook":
    case "linkedin":

    if(item.value.includes('://')) {
      return item.value;
    } else {
      return (`${getSocialLink(item.type)}${item.value}`);
    }
    // case "youtube":
    // case "embedded video":
    // case "apple music":
    // case "podcasts":
    // case "website":
    // case "paypal":
    // case "linktree":
    // case "yelp":
    // case "custom link":
    // case "file":
    // case "facetime":
    // case "contact card":
    // case "calendly":
    // case "etsy":
    // case "app link":
    // case "hoo.be":
    // case "opensea":
    // case "media kits":
    // case "square":
    // case "reviews":
    case "upi":
      return `upi://pay?pa=${item.value.trim().toLowerCase()}&amp;pn=${item.username}&amp;cu=INR`;
    case "venmo":
      return item.value;
    default:
      console.log(`${getSocialLink(item.type)}${item.value}`);
      return `${getSocialLink(item.type)}${item.value}`;
  }
};

export const getYoutubeId = (url) => {
  url = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  return url[2] !== undefined ? url[2].split(/[^0-9a-z_\-]/i)[0] : url[0];
};

export const getBgColor = (theme) => {
  if (theme === "#F4F4F4" || theme == null) {
    return "#000";
  } else if (theme) {
    return theme;
  } else {
    return "#000";
  }
};

export const getIconColor = (theme) => {
  if (theme === "#F4F4F4" || theme == null) {
    return "#000";
  } else if (theme === "#000000") {
    return "#f4f4f4";
  } else {
    return theme;
  }
};

export const handleExportVCF = (user) => {
  const url = `https://api.netwotap.com/profile/download/vcf/${user.uid}`;
  const tempData = {
    uid: user.uid,
    viewerUid: null,
  };
  addAnalytics("https://api.netwotap.com/analytics/stats/saved", tempData);
  window.open(url, "_blank");
};

export const hexToRGB = (hex, alpha) => {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
};

// START  ACTIONS
export const addAnalytics = (path, data) => {
  return new Promise((resolve, reject) => {
    Axios.post(path, data)
      .then((res) => {
        console.log("RES", res);
        if (res && res.status) {
          resolve(res.message);
        } else {
          reject(res ? res.message : "Something went wrong");
        }
      })
      .catch((err) => {
        reject(err.toString());
      });
  });
};
// END  ACTIONS
