import React, { useEffect } from "react";
import { Container, Row, Col } from "shards-react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";

import {
  addAnalytics,
  capitalize,
  getSocialIcon,
  getSocialUrl,
  getYoutubeId,
  handleExportVCF,
} from "../../utils";
import styles from "./ThemeThree.module.css";
import ContentModal from "../../components/contentModal";
import Spinning from "../../components/common/Spinning";

import profile from "../../assets/images/avatar.png";
import menuIcon from "../assets/icons/menu-w.png";
import PublicProducts from "../../components/PublicProducts";
import LinkCard from "../../components/LinkCard";
import CreateProfileButton from "../../components/CreateProfileButton";

const CountDiv = ({ title, count }) => (
  <div style={{ marginTop: 10 }}>
    <p className={styles.smallText}>{title}</p>
    <p className={styles.smallText} style={{ fontSize: 30 }}>
      {count}
    </p>
  </div>
);

const Button = ({
  onPress,
  text,
  backgroundColor = "#ccc",
  color = "#000",
}) => (
  <button
    onClick={onPress}
    className={styles.button}
    style={{ backgroundColor }}
  >
    <p className={styles.buttonText} style={{ color }}>
      {text}
    </p>
  </button>
);

const ThemeThree = ({
  user,
  embeddedLinks,
  highlightLinks,
  normalLinks,
  showModal,
  toggleModal,
}) => {
  useEffect(() => {
    const tempData = {
      uid: user.uid,
      viewerUid: null,
    };
    addAnalytics("https://api.netwotap.com/analytics/stats/view", tempData);
  }, []);

  const redirect = (item) => {
    if (user) {
      const tempData = {
        uid: user.uid,
        viewerUid: null,
        type: item.type,
        linkId: item.id,
      };
      addAnalytics("https://api.netwotap.com/analytics/stats/tap", tempData);
    }
    const url = getSocialUrl(item);
    window.open(url, "_blank");
  };

  return (
    <Container fluid className="main-content-container p-0">
      {user ? (
        <>
          <ContentModal
            showModal={showModal}
            toggleModal={toggleModal}
            uid={user.uid}
          />
          <div
            className={styles.page}
            style={{
              backgroundImage:
                user.activeProfile === "business"
                  ? `url('${user.biz_wallpaper}')`
                  : `url(${user.wallpaper})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div className={styles.pageContent}>
              <Container className="p-0" fluid>
                <Row>
                  <Col>
                    <div className={styles.header}>
                      <div>
                        <p className={styles.usernameText}>
                          {(user.activeProfile === "business"
                            ? user.biz_firstname
                            : user.firstname) +
                            " " +
                            (user.activeProfile === "business"
                              ? user.biz_lastname
                              : user.lastname)}
                        </p>
                        <p className={styles.smallText}>
                          {user.activeProfile === "business"
                            ? user.biz_location
                            : user.location}
                        </p>
                        <p className={styles.description}>
                          {user.activeProfile === "business"
                            ? user.biz_description
                            : user.description}
                        </p>
                      </div>
                      <div className={styles.content}>
                        <CountDiv
                          title="Profile Views"
                          count={user.profileViews}
                        />
                        <CountDiv
                          title="Content Downloads"
                          count={user.downloads}
                        />
                      </div>
                      <div className={styles.profileImgCover}>
                        <img
                          alt="profile"
                          src={
                            (user.activeProfile === "business"
                              ? user.biz_photo
                              : user.photo) || profile
                          }
                          className={styles.fullImg}
                        />
                      </div>
                      {/* <button className={styles.menu_iconCover}>
                      <img
                        alt="menu"
                        src={menuIcon}
                        className={styles.menu_icon}
                      />
                    </button> */}
                    </div>
                    {/* PART  BODY */}
                    <div className="text-center">
                      <p className={styles.shareText}>
                        Share your contacts with{" "}
                        {(user.activeProfile === "business"
                          ? user.biz_firstname
                          : user.firstname) +
                          " " +
                          (user.activeProfile === "business"
                            ? user.biz_lastname
                            : user.lastname)}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col className={styles.linksContent}>
                    {embeddedLinks.map((item) => (
                      <div key={item.id} className="mt-4 mx-3">
                        <LiteYouTubeEmbed
                          id={getYoutubeId(item.value)}
                          title="Embedded Video"
                        />
                      </div>
                    ))}
                    <div className="mt-5 mx-3">
                      {highlightLinks.map((item) => (
                        <LinkCard
                          key={item.id}
                          item={item}
                          handleClick={() => redirect(item)}
                          user={user}
                        />
                      ))}
                    </div>
                    {normalLinks.map((item) => (
                      <div
                        key={item.id}
                        onClick={() => redirect(item)}
                        className={styles.linkList}
                      >
                        <img
                          alt="link"
                          src={item.icon ? item.icon : getSocialIcon(item.type)}
                          className={styles.linkIcon}
                        />
                        <p className={styles.linkText}>
                          {item.title || capitalize(item.type)}
                        </p>
                      </div>
                    ))}
                    {user.categories.length > 0 && (
                      <div className="mt-5">
                        <PublicProducts
                          publicCategories={user.categories}
                          sellerUid={user.uid}
                          isTable={Boolean(user.isTable)}
                          isDelivery={Boolean(user.isDelivery)}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <div className={styles.footer}>
                  <Button
                    onPress={() => handleExportVCF(user)}
                    text="Save Contact"
                    backgroundColor="#4982f5"
                    color="#fff"
                  />
                  <Button text="My Company" />
                </div>
              </Container>
              <CreateProfileButton />
            </div>
          </div>
        </>
      ) : (
        <Spinning></Spinning>
      )}
    </Container>
  );
};

export default ThemeThree;
