import React from "react";
import { Spinner } from 'react-bootstrap';

const Spinning = ({ color = 'dark' }) => (
    <div style={styles.align} >
        <Spinner animation="grow" variant={color} />
    </div>
)

const styles = {
    align: { justifyContent: 'center', display: 'flex', marginTop: 30, marginBottom: 30 }
}

export default Spinning;