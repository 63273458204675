import React, { useState } from "react";
import { Container, Row, Col } from "shards-react";
import { Modal } from "react-bootstrap";
import Axios from "axios";
import styles from "./contentModal.module.css";
import { validateEmail } from "../../utils";

const ContentModal = ({ showModal, toggleModal, uid }) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [jobTitle, setJobTitle] = useState("");

  const handleSaveConnect = async () => {
    if (email && !validateEmail(email)) {
      window.alert("Invalid email");
      return;
    }

    setLoading(true);

    const locDataRes = await fetch(
      "https://asia-south1-flumble-328406.cloudfunctions.net/geoip"
    );
    const locData = await locDataRes.json();
    if (!locData) {
      window.alert("Something went wrong!, Please try later");
      return;
    }
    const data = {
      uid,
      name,
      email,
      phone: phoneNo,
      jobTitle,
      city: locData.city,
      lat: locData.cityLatLong.split(",")[0],
      lng: locData.cityLatLong.split(",")[1],
      viewerUid: null,
    };
    // console.log(data);
    Axios.post("https://api.netwotap.com/connections", data)
      .then((res) => {
        console.log(res);
        setLoading(false);
        toggleModal();
        window.alert("Created successfully");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <Modal
      show={showModal}
      ///backdrop="static"
      onHide={toggleModal}
      //containerClassName={styles.containerClassName}
      contentClassName={styles.contentClassName}
      //backdropClassName={styles.backdropClassName}
      //className={styles.modalClassName}
      //dialogClassName={styles.dialogClassName}
    >
      <Container fluid>
        <Row>
          <Col className="p-0">
            <div className="text-center mt-4 mb-3">
              <p className={styles.modalTitle}>Share your info with</p>
              <input
                className={styles.modalInput}
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <input
                className={styles.modalInput}
                type="email"
                placeholder="Your Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className={styles.modalInput}
                type="number"
                placeholder="Number"
                value={phoneNo}
                onChange={(e) => setPhoneNo(e.target.value)}
              />
              <input
                className={styles.modalInput}
                type="text"
                placeholder="Job Title"
                value={jobTitle}
                onChange={(e) => setJobTitle(e.target.value)}
              />
              <button
                className={styles.modalConnectBtn}
                onClick={handleSaveConnect}
                disabled={loading}
              >
                Connect
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default ContentModal;
