import React, { useState, useEffect } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { BottomSheet } from "react-spring-bottom-sheet";
import Axios from "axios";
import { capitalize, validateEmail } from "../../utils";
import styles from "./public-products.module.css";

import minusIcon from "../../assets/icons/minus.png";
import plusIcon from "../../assets/icons/plus.png";
import checkEmptyIcon from "../../assets/icons/checkbox-empty.png";
import checkTickIcon from "../../assets/icons/checkbox-tick.png";
import successTickIcon from "../../assets/icons/success-tick.png";
import dropdownIcon from "../../assets/icons/drop-down.png";
import dropupIcon from "../../assets/icons/drop-up.png";

const PublicProducts = ({
  publicCategories = [],
  sellerUid,
  isTable,
  isDelivery,
}) => {
  const activeCategories = () => {
    return publicCategories.filter((x) => x.active);
  };

  const activeProducts = () => {
    const prods = [];
    activeCategories().forEach((c) => {
      c.products
        .filter((p) => p.active)
        .forEach((f) => {
          prods.push(f);
        });
    });

    return prods;
  };

  const [loading, setLoading] = useState(false);
  const [render, setRender] = useState(0);

  const [showCategories, setShowCategories] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [paymentOption, setPaymentOption] = useState("cash");
  const [showPaymentStatus, setShowPaymentStatus] = useState(false);

  const [categories, setCategories] = useState(activeCategories);
  const [cartProducts, setCartProducts] = useState([]);

  const initialOrderTypes = () => {
    if (isDelivery && isTable) {
      return ["delivery", "dine in"];
    } else if (isDelivery) {
      return ["delivery"];
    } else if (isTable) {
      return ["dine in"];
    } else {
      return [];
    }
  };
  const initialOrderType = () => {
    if (isDelivery) {
      return "delivery";
    } else if (isTable) {
      return "dine in";
    } else {
      return null;
    }
  };
  const [orderTypes] = useState(initialOrderTypes);
  const [orderType, setOrderType] = useState(initialOrderType);

  const [name, setName] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [paymentType, setPaymentType] = useState("cash");
  const [tableNo, setTableNo] = useState("");

  const handleUpdateCategory = (item) => {
    if (item === "all") {
      setCategories(activeCategories());
    } else {
      setCategories([item]);
    }
    setShowCategories(false);
  };

  const handleOrder = async () => {
    // console.log(cartProducts);
    const conds =
      name &&
      phoneNo &&
      validateEmail(email) &&
      ((orderType === "delivery" && address) ||
        (orderType === "dine in" && tableNo)) &&
      cartProducts.length > 0;
    if (!conds) {
      alert("All fields required!");
      return;
    }
    const carts = [];
    cartProducts.forEach((item) => {
      carts.push({
        id: item.id,
        catId: item.catId,
        quantity: item.quantity,
      });
    });
    const data = {
      uid: sellerUid,
      name,
      phone: phoneNo,
      email,
      buyerUid: "",
      address,
      paymentMethod: paymentType,
      tableNo,
      delivery: orderType === "delivery" ? 1 : 0,
      products: carts,
    };
    console.log(data);
    setLoading(true);
    try {
      // const res = await dispatch(createOrder(data));
      // console.log(res);
      const res = await Axios.post("https://api.netwotap.com/orders", data);
      console.log(res);
      setShowOrderDetails(false);
      setShowPaymentStatus(true);
    } catch (error) {
      console.log(error);
      alert(error.toString());
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentReset = () => {
    setCartProducts([]);
    setShowPaymentStatus(false);
  };

  const handleQuantity = (item, type) => {
    const tempCarts = [...cartProducts];
    const isExistIdx = tempCarts.findIndex((x) => x.id === item.id);
    if (isExistIdx >= 0) {
      tempCarts[isExistIdx] = {
        ...tempCarts[isExistIdx],
        quantity:
          type === "plus"
            ? tempCarts[isExistIdx].quantity + 1
            : tempCarts[isExistIdx].quantity - 1,
      };
    } else {
      tempCarts.push({ ...item, quantity: 1 });
    }
    setCartProducts(tempCarts);
  };

  const cartProdItem = (item) => {
    return cartProducts.find((x) => x.id === item.id);
  };

  const prodSubtotal = () => {
    return cartProducts.reduce((prev, curr) => {
      return curr.price * (curr.quantity || 0) + prev;
    }, 0);
  };

  return (
    <>
      <Modal
        show={showCategories}
        ///backdrop="static"
        onHide={() => setShowCategories(false)}
        contentClassName={styles.contentClassName}
      >
        <div className={styles.ppCatModalContent}>
          <div
            onClick={() => handleUpdateCategory("all")}
            className="d-flex align-items-center justify-content-between mb-3"
          >
            <p className={styles.ppCatName}>All</p>
            <p className={styles.ppCatName}>{activeProducts().length}</p>
          </div>
          {activeCategories().map((item, index) => (
            <div
              key={item.id}
              onClick={() => handleUpdateCategory(item)}
              className="d-flex align-items-center justify-content-between mb-3"
            >
              <p className={styles.ppCatName}>{item.name}</p>
              <p className={styles.ppCatName}>
                {item.products.filter((x) => x.active).length}
              </p>
            </div>
          ))}
        </div>
      </Modal>
      <Modal
        show={showPaymentStatus}
        ///backdrop="static"
        onHide={() => setShowPaymentStatus(false)}
        contentClassName={styles.contentClassName}
      >
        <div className={styles.ppPaymentModalContent}>
          <img
            src={successTickIcon}
            alt="status"
            className={styles.ppPaymentStatusIcon}
          />
          <p className={styles.ppPaymentStatusText}>
            Order Placed Successfully
          </p>
          <button onClick={handlePaymentReset} className={styles.ppSaveBtn}>
            Close
          </button>
        </div>
      </Modal>
      {/* //* Bottom Sheet */}
      <BottomSheet
        open={showOrderDetails}
        onDismiss={() => setShowOrderDetails(false)}
      >
        <Container>
          <Row>
            <Col className="p-3">
              <p className={styles.ppBsTitle}>
                Enter order details to continue
              </p>
              <div className={styles.ppBsInputCover}>
                <label htmlFor="name" className={styles.ppBsLabel}>
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className={styles.ppBsInput}
                />
              </div>
              <div className={styles.ppBsInputCover}>
                <label htmlFor="phone" className={styles.ppBsLabel}>
                  Phone No
                </label>
                <input
                  id="phone"
                  name="phone"
                  type="number"
                  value={phoneNo}
                  onChange={(e) => setPhoneNo(e.target.value)}
                  className={styles.ppBsInput}
                />
              </div>
              <div className={styles.ppBsInputCover}>
                <label htmlFor="email" className={styles.ppBsLabel}>
                  Email
                </label>
                <input
                  type="email"
                  id="name"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={styles.ppBsInput}
                />
              </div>

              <div className="d-flex align-items-center justify-content-between mt-3">
                {orderTypes.map((x) => (
                  <div key={x} onClick={() => setOrderType(x)}>
                    <img
                      src={orderType === x ? checkTickIcon : checkEmptyIcon}
                      alt="check"
                      className={styles.ppPaymentIcon}
                    />
                    <span className={styles.ppPaymentText}>
                      {capitalize(x)}
                    </span>
                  </div>
                ))}
              </div>

              {orderType === "delivery" && (
                <div className={styles.ppBsTextareaCover}>
                  <label
                    htmlFor="address"
                    className={styles.ppBsLabel}
                    style={{ alignItems: "flex-start" }}
                  >
                    Address
                  </label>
                  <textarea
                    name="address"
                    id="address"
                    cols="30"
                    rows="4"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className={styles.ppBsInput}
                  ></textarea>
                </div>
              )}
              {orderType === "dine in" && (
                <div className={styles.ppBsInputCover}>
                  <label
                    htmlFor="table"
                    className={styles.ppBsLabel}
                    style={{ width: 100 }}
                  >
                    Table No
                  </label>
                  <input
                    type="number"
                    id="table"
                    name="table"
                    value={tableNo}
                    onChange={(e) => setTableNo(e.target.value)}
                    className={styles.ppBsInput}
                  />
                </div>
              )}

              <div className="d-flex align-items-center justify-content-between mt-3">
                <p className={styles.ppBsLabel} style={{ width: "auto" }}>
                  Payment Option
                </p>
                <div className="d-flex align-items-center">
                  {["cash"].map((x) => (
                    <div
                      key={x}
                      onClick={() => setPaymentOption(x)}
                      //className="mr-4"
                    >
                      <img
                        src={
                          paymentOption === x ? checkTickIcon : checkEmptyIcon
                        }
                        alt="check"
                        className={styles.ppPaymentIcon}
                      />
                      <span className={styles.ppPaymentText}>{x}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-5 mb-3">
                <button
                  onClick={() => setShowOrderDetails(false)}
                  className={styles.ppSaveBtn}
                >
                  Cancel
                </button>
                <button
                  disabled={loading}
                  onClick={handleOrder}
                  className={styles.ppSaveBtn}
                  style={{ background: "#00AB49" }}
                >
                  Pay Now
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </BottomSheet>
      {/* //? CONTENT */}
      <div className={styles.ppCard}>
        <div className="d-flex align-items-center justify-content-between p-3 pb-0">
          <p className={styles.ppProductsText}>Products</p>
          <p
            onClick={() => setShowCategories(true)}
            className={styles.ppBrowserBtnText}
          >
            Browse All
          </p>
        </div>
        {/* //* Products */}
        {categories.map((catItem, catIndex) => (
          <div className={styles.ppProdCover}>
            <div
              onClick={() => {
                catItem.show = catItem.show ? false : true;
                setRender(Math.random(3));
              }}
              className="d-flex align-items-center justify-content-between px-3 py-2 my-2"
              style={{ backgroundColor: "#eee" }}
            >
              <p className={styles.ppCatName}>{catItem.name}</p>
              <img
                src={catItem.show ? dropupIcon : dropdownIcon}
                alt="dropdown"
                className={styles.ppArrowIcon}
              />
            </div>
            {catItem.show && (
              <>
                {catItem.products.length > 0 ? (
                  <>
                    {catItem.products.map((item, index) => (
                      <div
                        key={item.id}
                        style={{ borderBottom: "1px solid #d9d6d650" }}
                        className="d-flex align-items-center justify-content-between px-3"
                      >
                        <div>
                          <p className={styles.ppProdName}>{item.name}</p>
                          <p className={styles.ppProdDesc}>
                            {item.description}
                          </p>
                          <div className="d-flex align-items-center">
                            <p className={styles.ppProdMrp}>$ {item.mrp}</p>
                            <p className={styles.ppProdPrice}>$ {item.price}</p>
                          </div>
                        </div>
                        <div>
                          <div className={styles.ppPhotoCover}>
                            <img
                              src={item.image}
                              alt="product"
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                          <>
                            {cartProdItem(item) &&
                            cartProdItem(item).quantity ? (
                              <div className={styles.ppAddBtn}>
                                <div
                                  onClick={() => handleQuantity(item, "minus")}
                                  className={styles.ppImgBtn}
                                >
                                  <img
                                    src={minusIcon}
                                    alt="minus"
                                    className={styles.ppQtyImg}
                                  />
                                </div>
                                <p className="mx-2 mb-0">
                                  {cartProdItem(item).quantity}
                                </p>
                                <div
                                  onClick={() => handleQuantity(item, "plus")}
                                  className={styles.ppImgBtn}
                                >
                                  <img
                                    src={plusIcon}
                                    alt="plus"
                                    className={styles.ppQtyImg}
                                  />
                                </div>
                              </div>
                            ) : (
                              <div
                                onClick={() => handleQuantity(item, "plus")}
                                className={styles.ppAddBtn}
                              >
                                Add
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <div style={{ marginTop: 20 }}>
                    <p className={styles.ppNotFound}>No product found!!!</p>
                  </div>
                )}
              </>
            )}
          </div>
        ))}

        {/* {products.length > 0 ? (
          <div className={styles.ppProdCover}>
            {products.map((item, index) => (
              <div
                key={item.id}
                style={{ borderBottom: "1px solid #d9d6d650" }}
                className="d-flex align-items-center justify-content-between p-3"
              >
                <div>
                  <p className={styles.ppProdName}>{item.name}</p>
                  <p className={styles.ppProdDesc}>{item.description}</p>
                  <div className="d-flex align-items-center">
                    <p className={styles.ppProdMrp}>$ {item.mrp}</p>
                    <p className={styles.ppProdPrice}>$ {item.price}</p>
                  </div>
                </div>
                <div>
                  <div className={styles.ppPhotoCover}>
                    <img
                      src={item.image}
                      alt="product"
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  <>
                    {cartProdItem(item) && cartProdItem(item).quantity ? (
                      <div className={styles.ppAddBtn}>
                        <div
                          onClick={() => handleQuantity(item, "minus")}
                          className={styles.ppImgBtn}
                        >
                          <img
                            src={minusIcon}
                            alt="minus"
                            className={styles.ppQtyImg}
                          />
                        </div>
                        <p className="mx-2 mb-0">
                          {cartProdItem(item).quantity}
                        </p>
                        <div
                          onClick={() => handleQuantity(item, "plus")}
                          className={styles.ppImgBtn}
                        >
                          <img
                            src={plusIcon}
                            alt="plus"
                            className={styles.ppQtyImg}
                          />
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => handleQuantity(item, "plus")}
                        className={styles.ppAddBtn}
                      >
                        Add
                      </div>
                    )}
                  </>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div style={{ marginTop: 20 }}>
            <p className={styles.ppNotFound}>No product found!!!</p>
          </div>
        )} */}
        {/* //* Footer */}
        <div className="d-flex align-items-center justify-content-between p-3 mt-4">
          <p className={styles.ppSubtotalText}>Subtotal ${prodSubtotal()}</p>
          <button
            disabled={prodSubtotal() === 0}
            onClick={() => setShowOrderDetails(true)}
            className={styles.ppSaveBtn}
          >
            Order now
          </button>
        </div>
      </div>
    </>
  );
};

export default PublicProducts;
