import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import ReactNotification from "react-notifications-component";
import routes from "./routes";
import withTracker from "./withTracker";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/styles/shards-dashboards.1.1.0.css";
import "react-notifications-component/dist/theme.css";
import "rodal/lib/rodal.css";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import "react-spring-bottom-sheet/dist/style.css";
import "./assets/styles/custom.css";

export default () => {
  console.log = function() {}

  return (
      <Router basename={process.env.REACT_APP_BASENAME || ""}>
        <div>
          <ReactNotification />
          {routes.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={withTracker((props) => {
                  return (
                    <route.layout {...props}>
                      <route.component {...props} />
                    </route.layout>
                  );
                })}
              />
            );
          })}
        </div>
      </Router>
  );
};
