import React from "react";
import MobileDetect from "mobile-detect";
import styles from "./create-profile-button.module.css";

const CreateProfileButton = () => {
  const type = new MobileDetect(window.navigator.userAgent);

  const handleToStore = () => {
    if (type.os() === "iOS") {
      window.open(
        "https://apps.apple.com/in/app/flumble-digital-business-card/id1596095103",
        "_self"
      );
    } else if (type.os() === "AndroidOS") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.netwotap",
        "_self"
      );
    } else {
      window.open("https://netwotap.me/", "_self");
    }
  };

  return (
    <div className={styles.buttonPosition}>
      <button onClick={handleToStore} className={styles.button}>
        Create your own profile
      </button>
    </div>
  );
};

export default CreateProfileButton;
