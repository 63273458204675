export default function() {
  return [
    {
      title: "Dashboard",
      to: "/dashboard",
      htmlBefore: '<i class="fas fa-th-large"></i>',
      htmlAfter: ""
    },
    {
      title: "Tounaments",
      htmlBefore: '<i class="fas fa-trophy"></i>',
      to: "/tournaments",
    },
    {
      title: "Chat",
      htmlBefore: '<i class="fas fa-comments"></i>',
      to: "/chat",
    },
    {
      title: "Users",
      htmlBefore: '<i class="fas fa-users"></i>',
      to: "/users",
    },
    {
      title: "Support",
      htmlBefore: '<i class="fas fa-life-ring"></i>',
      to: "/Support",
    },
    {
      title: "Feedbacks",
      htmlBefore: '<i class="fas fa-comment"></i>',
      to: "/feedbacks",
    },
    // {
    //   title: "Settings",
    //   htmlBefore: '<i class="fas fa-cog"></i>',
    //   to: "/settings",
    // },
   
   
  ];
}
