import React, { useState, useEffect } from "react";
import { Container } from "shards-react";
import { useHistory, useParams } from "react-router-dom";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import {
  capitalize,
  getBgColor,
  getIconColor,
  getSocialIcon,
  getSocialUrl,
  getSocialSmIcon,
  handleExportVCF,
  hexToRGB,
  getYoutubeId,
  addAnalytics,
} from "../../utils";
import Axios from "axios";
import Spinning from "../../components/common/Spinning";
import styles from "./profile.module.css";

import cover from "../../assets/images/cover.png";
import profile from "../../assets/images/avatar.png";

import ThemeOne from "./ThemeOne";
import ThemeTwo from "./ThemeTwo";
import ThemeThree from "./ThemeThree";
import ContentModal from "../../components/contentModal";
import PublicProducts from "../../components/PublicProducts";
import LinkCard from "../../components/LinkCard";
import CreateProfileButton from "../../components/CreateProfileButton";
import InteracModal from '../../components/interacModal';

const ProfileIcon = ({ fill = "#fff" }) => (
  <svg width="40" height="40" viewBox="0 0 32 26" fill={fill} className="css-0">
    <path d="M19.198 25.411c6.834 0 12.486-5.664 12.486-12.499 0-6.822-5.665-12.498-12.5-12.498C12.867.414 7.568 5.26 6.8 11.364a6 6 0 01.957.076c.39.05.78.138 1.158.251a10.32 10.32 0 0110.27-9.138c5.753 0 10.36 4.62 10.372 10.36 0 2.68-.994 5.097-2.63 6.935-1.9-1.586-4.745-2.644-7.741-2.644-1.41 0-3.008.302-4.494.831.088.479.139.97.139 1.46a8.066 8.066 0 01-1.385 4.494 12.506 12.506 0 005.753 1.422zm-.013-10.372c2.417 0 4.242-2.089 4.242-4.72 0-2.48-1.863-4.632-4.242-4.632-2.366 0-4.242 2.153-4.242 4.632 0 2.631 1.838 4.72 4.242 4.72zM6.699 25.89c3.474 0 6.394-2.895 6.394-6.394s-2.883-6.394-6.394-6.394c-3.5 0-6.394 2.895-6.394 6.394 0 3.524 2.895 6.394 6.394 6.394zm.012-2.24c-.44 0-.83-.302-.83-.78v-2.606H3.477a.782.782 0 01-.78-.768c0-.428.352-.78.78-.78H5.88v-2.606c0-.465.39-.767.83-.767.428 0 .819.302.819.767v2.606h2.404a.774.774 0 010 1.548H7.53v2.605c0 .479-.39.78-.819.78z"></path>
  </svg>
);
const Profile = () => {
  var history = useHistory();

  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showInterac, setShowInterac] = useState(false);
  const [interacEmail, setInteracEmail] = useState('');

  const { linkId } = useParams();

  useEffect(()=> {
    if(!linkId) {
      window.location.href = "https://netwotap.com";
    }
  }, []);

  useEffect(() => { 
    if(linkId === 'redirect') {
      const query = new URLSearchParams(window.location.search);
      const ref = query.get('ref');
      if(ref) {
          window.location.href = ref;
      }
    }
  }, [linkId]);

  const redirect = (item) => {
    // const url = `${getSocialLink(item.type)}${item.value}`;
    if (user && user != null) {
      const tempData = {
        uid: user.uid,
        viewerUid: null,
        type: item.type,
        linkId: item.id,
      };
      addAnalytics("https://api.netwotap.com/analytics/stats/tap", tempData);
      item.username = user?.firstname +' '+user?.lastname;
    }
    const url = getSocialUrl(item);

    if(item.type !== 'interac') {
      window.open(url, "_self");
    } else {
      setInteracEmail(item.value.toLowerCase());
      setShowInterac(true);
    }
    // window.open(url, "_blank");
  };

  useEffect(() => {
    Axios.get(`https://api.netwotap.com/profile/link/${linkId}`)
      .then((res) => {
        let data = res.data.data;
        setUser(data);
        setLoading(false);
        console.log(data);
        if (
          data.activeProfile === "business" &&
          data.biz_DirectOn === 1 &&
          data.biz_directOnLink
        ) {
          const linkItem = data.links.find(
            (x) => x.id == data.biz_directOnLink
          );
          if (linkItem) {
            redirect(linkItem);
          }
        } else if (
          data.activeProfile === "personal" &&
          data.directOn === 1 &&
          data.directOnLink
        ) {
          const linkItem = data.links.find((x) => x.id == data.directOnLink);
          if (linkItem) {
            redirect(linkItem);
          }
        }

        if (
          (data.activeProfile === "personal" && data.leadCapture === 1) ||
          (data.activeProfile === "business" && data.biz_leadCapture === 1)
        ) {
          toggleModal();
        }

        // PART  Analytics
        const tempData = {
          uid: data.uid,
          viewerUid: null,
        };
        addAnalytics("https://api.netwotap.com/analytics/stats/view", tempData);
      })
      .catch((err) => {
        console.log("Err", err);
        setLoading(false);
      });
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleInterac = () => {
    setShowInterac(!showInterac);
  };

  const embeddedLinks = () => {
    return user.links
      .filter(
        (x) =>
          (x.isPersonal == 1 && user.activeProfile === "personal") ||
          (x.isPersonal == 0 &&
            user.activeProfile === "business" &&
            x.active == 1)
      )
      .filter(
        (x) =>
          //x.type.toLowerCase() === "youtube" ||
          x.type.toLowerCase() === "embedded video"
      );
  };

  const highlightLinks = () => {
    return user.links
      .filter(
        (x) =>
          (x.isPersonal == 1 && user.activeProfile === "personal") ||
          (x.isPersonal == 0 &&
            user.activeProfile === "business" &&
            x.active == 1)
      )
      .filter(
        (x) =>
          //x.type.toLowerCase() !== "youtube" &&
          x.type.toLowerCase() !== "embedded video" && x.highlight
      );
  };

  const normalLinks = () => {
    return user.links
      .filter(
        (x) =>
          (x.isPersonal == 1 && user.activeProfile === "personal") ||
          (x.isPersonal == 0 &&
            user.activeProfile === "business" &&
            x.active == 1)
      )
      .filter(
        (x) =>
          //x.type.toLowerCase() !== "youtube" &&
          x.type.toLowerCase() !== "embedded video" && !x.highlight
      );
  };

  const finalTheme =
    user && (user.activeProfile === "business" ? user.biz_theme : user.theme);

  // !  only for testing
  // if (user) {
  //   return (
  //     <ThemeThree
  //       user={user}
  //       embeddedLinks={embeddedLinks()}
  //       highlightLinks={highlightLinks()}
  //       normalLinks={normalLinks()}
  //       showModal={showModal}
  //       toggleModal={toggleModal}
  //     />
  //   );
  // }

  if(!linkId) {
    return <></>;
  }

  if(linkId === 'redirect') {
    return(<Spinning></Spinning>);
  }

  if (
    user &&
    ((user.activeProfile === "business" && user.biz_theme == "bright") ||
      (user.activeProfile === "personal" && user.theme == "bright"))
  ) {
    return (
      <>
      {/* MODAL */}
      <InteracModal
            showModal={showInterac}
            toggleModal={toggleInterac}
            uid={user.uid}
            email={interacEmail}
          />
      <ThemeOne
        user={user}
        embeddedLinks={embeddedLinks()}
        highlightLinks={highlightLinks()}
        normalLinks={normalLinks()}
        showModal={showModal}
        toggleModal={toggleModal}
      />
      </>
    );
  }

  if (
    user &&
    ((user.activeProfile === "business" && user.biz_theme == "dark") ||
      (user.activeProfile === "personal" && user.theme == "dark"))
  ) {
    return (
      <>
      {/* MODAL */}
      <InteracModal
            showModal={showInterac}
            toggleModal={toggleInterac}
            uid={user.uid}
            email={interacEmail}
          />
      <ThemeTwo
        user={user}
        embeddedLinks={embeddedLinks()}
        highlightLinks={highlightLinks()}
        normalLinks={normalLinks()}
        showModal={showModal}
        toggleModal={toggleModal}
      />
      </>
    );
  }

  if (
    user &&
    ((user.activeProfile === "business" && user.biz_theme == "solid") ||
      (user.activeProfile === "personal" && user.theme == "solid"))
  ) {
    return (
      <>
      {/* MODAL */}
      <InteracModal
            showModal={showInterac}
            toggleModal={toggleInterac}
            uid={user.uid}
            email={interacEmail}
          />
      <ThemeThree
        user={user}
        embeddedLinks={embeddedLinks()}
        highlightLinks={highlightLinks()}
        normalLinks={normalLinks()}
        showModal={showModal}
        toggleModal={toggleModal}
      />
      </>
    );
  }

  return (
    <Container fluid className="main-content-container p-0">
      {user ? (
        <div
          className={styles.page}
          style={{
            backgroundColor:
              finalTheme !== "#F4F4F4"
                ? finalTheme !== "#000000"
                  ? hexToRGB(getBgColor(finalTheme), 0.35)
                  : "#000"
                : "#FFF",
          }}
        >
          {/* MODAL */}
          <ContentModal
            showModal={showModal}
            toggleModal={toggleModal}
            uid={user.uid}
          />
          {/* MODAL */}
          <InteracModal
            showModal={showInterac}
            toggleModal={toggleInterac}
            uid={user.uid}
            email={interacEmail}
          />
          <div
            className="overlay"
            style={{
              backgroundImage:
                user.activeProfile === "business"
                  ? `url('${user.biz_wallpaper}')`
                  : `url(${user.wallpaper})`,
              filter: `opacity(0.8)`,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundColor: "#fff",
            }}
          ></div>
          <div className="position-relative">
            <div className={styles.coverContainer}>
              <img
                className={styles.cover}
                alt=""
                src={
                  (user.activeProfile === "business"
                    ? user.biz_coverPhoto
                    : user.coverPhoto) || cover
                }
              />
            </div>
            <div className={styles.dpContainer}>
              <img
                className={styles.dp}
                alt=""
                src={
                  (user.activeProfile === "business"
                    ? user.biz_photo
                    : user.photo) || profile
                }
              />
            </div>
            {user.activeProfile === "business" && user.biz_logo && (
              <div className={styles.logo_cover}>
                <img
                  alt="logo"
                  src={user.biz_logo}
                  style={{ width: "100%", height: "100%" }}
                  resizeMode="cover"
                />
              </div>
            )}
            <div className={styles.box}>
              <h3 className={styles.title}>
                {(user.activeProfile === "business"
                  ? user.biz_firstname
                  : user.firstname) +
                  " " +
                  (user.activeProfile === "business"
                    ? user.biz_lastname
                    : user.lastname)}
              </h3>
              <p className={styles.description}>
                {user.activeProfile === "business"
                  ? user.biz_description
                  : user.description}
              </p>
            </div>
            <div className={styles.btnContainer}>
              <button
                onClick={toggleModal}
                //disabled={
                //  (user.activeProfile === "personal" && user.leadCapture === 0) ||
                //  (user.activeProfile === "business" &&
                //    user.biz_leadCapture === 0)
                //}
                className={styles.btn}
                style={{
                  backgroundColor:
                    finalTheme !== "#000000" ? getBgColor(finalTheme) : "#FFF",
                  color: finalTheme !== "#000000" ? "#FFF" : "#000",
                }}
              >
                Connect With Me
              </button>
              <button
                className={styles.profileIconbtn}
                onClick={() => handleExportVCF(user)}
              >
                <ProfileIcon
                  fill={getIconColor(finalTheme)}
                  className={styles.profileIcon}
                />
              </button>
            </div>
            {embeddedLinks().map((item, index) => (
              <div key={index} className="mt-4 mx-3">
                <LiteYouTubeEmbed
                  id={getYoutubeId(item.value)}
                  title="Embedded Video"
                />
              </div>
            ))}

            <div className="mt-4 mx-3" style={{ position: "relative" }}>
              {highlightLinks().map((item) => (
                <LinkCard
                  key={item.id}
                  item={item}
                  handleClick={() => redirect(item)}
                  user={user}
                />
              ))}
            </div>

            <div className={styles.linkSection}>
              <div className={styles.grid}>
                {normalLinks().map((item) => (
                  <div key={item.id} className={styles.gridItem}>
                    {(user.activeProfile === "personal" &&
                      user.colorLinkIcons) ||
                    (user.activeProfile === "business" &&
                      user.biz_colorLinkIcons) ? (
                      <button
                        style={{ backgroundColor: getBgColor(finalTheme) }}
                        className={styles.linkBtn}
                        onClick={() => redirect(item)}
                      >
                        <img
                          src={
                            item.icon ? item.icon : getSocialSmIcon(item.type)
                          }
                          alt=""
                          className={styles.link}
                        />
                      </button>
                    ) : (
                      <button
                        key={item.id}
                        className={styles.linkBtn}
                        onClick={() => redirect(item)}
                      >
                        <img
                          src={item.icon ? item.icon : getSocialIcon(item.type)}
                          alt=""
                          className={styles.link}
                        />
                      </button>
                    )}

                    <span className={styles.caption}>
                      {item.title || capitalize(item.type)}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            {user.categories.length > 0 && (
              <PublicProducts
                publicCategories={user.categories}
                sellerUid={user.uid}
                isTable={Boolean(user.isTable)}
                isDelivery={Boolean(user.isDelivery)}
              />
            )}
            <CreateProfileButton />
          </div>
        </div>
      ) : (
        <>
          {loading ? (
            <Spinning></Spinning>
          ) : (
            <div className={styles.msgContainer}>
              This user’s profile is not available.
            </div>
          )}
        </>
      )}
    </Container>
  );
};

export default Profile;
