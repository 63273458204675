import React from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import Avatar, { ConfigProvider } from "react-avatar";

export default class UserActions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      user: []
    };

  

    this.toggleUserActions = this.toggleUserActions.bind(this);
  }

  componentDidMount() {
    const user = localStorage.getItem('user');
    this.setState({user: JSON.parse(user)});

  }
  toggleUserActions() {
    this.setState({
      visible: !this.state.visible
    });
  }

  render() {
    const user = JSON.parse(localStorage.getItem('user'));

    return (
      <NavItem tag={Dropdown} caret toggle={this.toggleUserActions}>
        <DropdownToggle caret tag={NavLink} className="text-nowrap px-3">
        <ConfigProvider colors={['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#3267ef', '#4caf50', '#ff5722']}>
                                                                     <Avatar name={user?user.username:'Admin'} 
                                                                     src={user&&user.pp!=null ? "https://s4m-api.herokuapp.com/"+user.pp : null}
                                                                      size={35} round={true} />
                                                                </ConfigProvider>
                                                               {" "}
          <span className="d-none d-md-inline-block pl-1">{user?user.username:'Admin'}</span>
        </DropdownToggle>
        <Collapse tag={DropdownMenu} right small open={this.state.visible}>
          <DropdownItem tag={Link} to="/profile">
            <i className="material-icons">&#xE7FD;</i> Profile
          </DropdownItem>
          {/* <DropdownItem tag={Link} to="change-password">
            <i className="material-icons">&#xe897;</i> Change Password
          </DropdownItem> */}
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/login" className="text-danger">
            <i className="material-icons text-danger">&#xE879;</i> Logout
          </DropdownItem>
        </Collapse>
      </NavItem>
    );
  }
}
